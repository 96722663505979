import AuthStore from "../auth";
import BusinessUserStore from "../businessUser/businessUser";
import CommonStore from "../common/common";
import CommonUtils from "../../utils/commonUtils";
import RootStore from "../root";
import SearchStore from "../search/search";
import urlBuilder from "../../utils/urlBuilder";
import {AccountMgmt} from "../../types/features";
import {apiStatus} from "../../utils/constants";
import {BusinessUser} from "../../types/businessUser";
import {LegacyUser} from "../../types/acctMgmt/legacyUser";
import {NotificationMessages} from "../common/messaging/notificationMessages";
import {User} from "../../types/acctMgmt/user";
import GeneratePasswordBrowser from 'generate-password-browser';
import {makeAutoObservable, runInAction} from "mobx";

import {
    createLegacyUser,
    deactivateUser,
    editLegacyUser,
    getLegacyUserListByCompCode,
    getLegacyUsersAuthStatus,
    getUserListByCompCode,
    reactivateUser,
    suspendAll,
    suspendUser,
    unsuspendAll,
    unsuspendUser,
    updateLegacyUsersAuthStatus
} from "../../clients/uasApi";
import {CnameAuthStatus} from "../../types/acctMgmt/cnameAuthStatus";
import InviteUserStore from "./inviteUser";
import ModifyUserStore from "./modifyUser";
import ReinviteUserStore from "./reinviteUser";
import MoveUserStore from "./moveUser";

export const DEFAULT_REMOTE_ENV = "auth0"
export default class AcctMgmtStore {

    // Fields / Types
    businessUser: BusinessUser | null = null;
    compCode: string = "";
    editingUser: User | null = null;
    userRecords: Array<User> = new Array<User>();
    legacyUserRecords: Array<LegacyUser> = new Array<LegacyUser>();
    multiLocationFlagRefs: Map<string,any> = new Map<string,any>();
    currentTab : number = 0;
    selectedTab : number = 0;
    editingLegacyUser: LegacyUser | null = null;
    originalLegacyUser: LegacyUser | null = null;
    createLegacyUserName: string = "";
    legacyUserStatusOptions: Array<string> = new Array<string>();
    cnameAuthStatus: CnameAuthStatus = {} as CnameAuthStatus;
    isDevCfoRemoteLoginModalVisible: boolean = false;
    devCfoRemoteLoginEnv: string = "";
    devCfoRemoteLoginResolvedUrl: string = "";
    validDevCfoRemoteLoginEnvErrorMessage: string = "";
    selectedUser: User | undefined = undefined;
    paginationCurrentPage: number = 1;
    paginationPerPageValue: number = 100;
    totalUsers: number = 0;
    totalActiveUsers: number = 0;

    //Modal control
    isCreateLegacyUserModalVisible: boolean = false;
    isEditLegacyUserModalVisible: boolean = false;
    isConfirmSuspendAllModalVisible: boolean = false;
    isConfirmSuspendUserModalVisible: boolean = false;
    isConfirmDeactivateDeleteModalVisible: boolean = false;
    isCnameAuthStatusModalVisible: boolean = false;


    // Stores
    rootStore: RootStore;
    authStore: AuthStore;
    businessUserStore: BusinessUserStore;
    commonStore: CommonStore;
    searchStore: SearchStore;
    inviteUserStore: InviteUserStore;
    modifyUserStore: ModifyUserStore;
    reinviteUserStore: ReinviteUserStore;
    moveUserStore: MoveUserStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.authStore = this.rootStore.authStore
        this.businessUserStore = this.rootStore.businessUserStore
        this.commonStore = this.rootStore.commonStore
        this.searchStore = this.rootStore.searchStore
        this.inviteUserStore = new InviteUserStore(rootStore)
        this.modifyUserStore = new ModifyUserStore(rootStore)
        this.reinviteUserStore = new ReinviteUserStore(rootStore)
        this.moveUserStore = new MoveUserStore(rootStore)

        makeAutoObservable(this)
    };

    public initializeStore() {
        this.compCode = this.businessUserStore?.businessUser?.compCode || "";
        runInAction(() => {
            this.commonStore.currentFeature = AccountMgmt;
        })
    };

    public setCurrentTab(selectedTab: number) {
        this.currentTab = selectedTab;
    };

    public setSelectedTab(selected: number) {
        this.selectedTab = selected
    };

    public getUsersByCompCode = async (pageNumber?: number) => {

        this.compCode = this.businessUserStore?.businessUser?.compCode || "";
        this.paginationCurrentPage = pageNumber || 1;

        CommonUtils.logNewRelicPageAction("Dealer Account Management", {userAction: 'Get Users by Comp Code', compCode: this.compCode})

        await getUserListByCompCode(this.authStore.token, this.compCode, this.paginationPerPageValue, this.paginationCurrentPage).then((resp: any) => {
            switch(resp?.status){
                case apiStatus.success :
                    runInAction(() => {
                        this.userRecords = resp.data.users;
                        this.totalUsers = resp.data.totalUsers;
                        this.totalActiveUsers = resp.data.totalActiveUsers;
                    });
                    break;
                case apiStatus.forbidden :
                    this.commonStore.displayPageNotification(resp.damDefaultErrorMessage, "orange")
                    break;
                default :
                    CommonUtils.displayConsoleLogError(`*** Error calling UAS to retrieve users for comp code ${this.compCode}.  Response: ` + JSON.stringify(resp));
                    this.commonStore.displayPageNotification(`Severe error occurred trying to load users for comp code '${this.compCode}'.`, "red")
                    break;
            }
        });
        this.moveUserStore.selectedUsers = []
    };

    public getLegacyUsersByCompCode = async () => {
        this.compCode = this.businessUserStore?.businessUser?.compCode || "";

        CommonUtils.logNewRelicPageAction("Dealer Account Management", {userAction: 'Get Legacy Users by Comp Code', compCode: this.compCode})

        await getLegacyUserListByCompCode(this.authStore.token, this.compCode).then((resp: any) => {
            switch(resp?.status){
                case apiStatus.success :
                    runInAction(() => {
                        this.legacyUserRecords = resp.data;
                    });
                    break;
                case apiStatus.forbidden :
                    this.commonStore.displayPageNotification(resp.damDefaultErrorMessage, "orange")
                    break;
                default :
                    CommonUtils.displayConsoleLogError(`*** Error calling UAS to retrieve Legacy users for comp code ${this.compCode}.  Response: ` + JSON.stringify(resp));
                    this.commonStore.displayPageNotification(`Severe error occurred trying to load legacy users for comp code '${this.compCode}'.`, "red")
                    break;
            }
        });
    };

    public getLegacyUsersAuthStatus = async () => {
        this.compCode = this.businessUserStore?.businessUser?.compCode || "";
        this.cnameAuthStatus = {} as CnameAuthStatus;

        await getLegacyUsersAuthStatus(this.authStore.token, this.compCode).then((resp: any) => {
            switch(resp?.status){
                case apiStatus.success :
                    runInAction(() => {
                        this.cnameAuthStatus = {...resp.data}
                    });
                    break;
                case apiStatus.forbidden :
                    this.commonStore.displayPageNotification(resp.damDefaultErrorMessage, "orange")
                    break;
                default :
                    CommonUtils.displayConsoleLogError(`*** Error calling UAS to retrieve Legacy users Auth Status for comp code ${this.compCode}.  Response: ` + JSON.stringify(resp));
                    this.commonStore.displayPageNotification(`Severe error occurred trying to load legacy users auth status for comp code '${this.compCode}'.`, "red")
                    break;
            }
        });
    };


    public updateLegacyUsersAuthStatus = async () => {
        this.commonStore.processingApiCall(true);
        CommonUtils.logNewRelicPageAction("Dealer Account Management", {userAction: 'Modify Legacy Users Auth Status', compCode: this.compCode})

        await updateLegacyUsersAuthStatus(this.authStore.token, this.compCode).then((resp: any) => {
            switch (resp?.status) {
                case apiStatus.success :
                    this.commonStore.displayFeatureNotification(NotificationMessages.UPDATE_SUCCESSFUL, "green");
                    break;
                case apiStatus.forbidden :
                    this.commonStore.displayPageNotification(resp.damDefaultErrorMessage, "orange")
                    break;
                default :
                    CommonUtils.displayConsoleLogError(`*** Error calling UAS to update legacy users auth status for comp code ${this.compCode}.  Response: ` + JSON.stringify(resp));
                    this.commonStore.displayPageNotification(`Severe error occurred trying to update legacy users auth status for comp code '${this.compCode}'.`, "red")
                    break;
            }
        });
        this.commonStore.processingApiCall(false);
    };

    public onCompCodeClick = (businessUser: any) => {
        this.searchStore.compCodeChange(businessUser.compCode);
    };

    public cfoLoginUasUser = async (selectedUser: any) => {
        let cfoRemoteLoginUrl = urlBuilder.buildCfoRemoteLoginUrl(selectedUser)
        window.open(cfoRemoteLoginUrl,  "cfo_remote");
    }

    public reactivateUser = async (selectedUser: any) : Promise<any> => {
        CommonUtils.logNewRelicPageAction("Dealer Account Management", {userAction: 'Reactivate User', compCode: this.compCode, user: selectedUser.email});

        let reactivated = false;
        this.commonStore.processingApiCall(true);

        await reactivateUser(this.authStore.token, this.authStore.user?.email!, selectedUser.userId).then((resp:any) => {
            switch(resp?.status){
                case apiStatus.success :
                    reactivated = true;
                    break;
                default :
                    if (resp?.status === apiStatus.conflict && resp?.damDefaultErrorMessage === 'phone in use' && resp?.data) {
                        this.commonStore.displayPageNotification(`The phone number for the user you are attempting to activate is already in use with ${resp?.data?.user} on ${resp?.data?.compCode}.`, "red");
                    } else {
                        this.commonStore.displayPageNotification(`Error occurred trying to reactivate ${selectedUser?.email}.`, "red");
                    }
                    CommonUtils.displayConsoleLogError(`*** Error calling UAS to reactivate user: ${selectedUser?.email}.  Response: ` + JSON.stringify(resp));
                    this.commonStore.processingApiCall(false);
                    break;
            }
        });

        if(reactivated) {
            await Promise.all([
                this.getUsersByCompCode()
            ]);
            this.commonStore.displayFeatureNotification(`Successfully reactivated ${selectedUser?.email}.`, "green");
            this.commonStore.processingApiCall(false);
        }
    };

    public deactivateUser = async (selectedUser: any) : Promise<any> => {

        CommonUtils.logNewRelicPageAction("Dealer Account Management", {userAction: 'Deactivate User', compCode: this.compCode, user: selectedUser.email});

        let deactivated = false;
        this.commonStore.processingApiCall(true);

        await deactivateUser(this.authStore.token, this.authStore.user?.email! ,selectedUser.userId).then((resp:any) => {
            switch(resp?.status){
                case apiStatus.success :
                    deactivated = true;
                    break;
                default :
                    CommonUtils.displayConsoleLogError(`*** Error calling UAS to deactivate user: ${selectedUser?.email}.  Response: ` + JSON.stringify(resp));
                    this.commonStore.displayPageNotification(`Error occurred trying to deactivate ${selectedUser?.email}.`, "red");
                    this.commonStore.processingApiCall(false);
                    break;
            }
        });

        if(deactivated) {
            await Promise.all([
                this.getUsersByCompCode()
            ]);
            let successMessage = `Successfully deactivated ${selectedUser?.email}.`
            if(this.isUserInvitedOrReinvited(selectedUser)) {
                successMessage = `Successfully deleted ${selectedUser?.email}.`
            }
            this.commonStore.displayFeatureNotification(successMessage, "green");
            this.commonStore.processingApiCall(false);
        }
    };

    public suspendAllUsers = async () => {
        this.commonStore.processingApiCall(true);
        CommonUtils.logNewRelicPageAction("Dealer Account Management", {userAction: 'Suspend All', compCode: this.compCode})

        await suspendAll(this.authStore.token, this.authStore.user?.email!, this.compCode).then((resp: any) => {
            switch (resp?.status) {
                case apiStatus.success :
                    this.getUsersByCompCode().then(() => undefined)
                    this.commonStore.displayFeatureNotification("All users successfully suspended", "green");
                    break;
                case apiStatus.forbidden :
                    this.commonStore.displayPageNotification(resp.damDefaultErrorMessage, "orange")
                    break;
                default :
                    CommonUtils.displayConsoleLogError(`*** Error calling UAS to suspend users for comp code ${this.compCode}.  Response: ` + JSON.stringify(resp));
                    this.commonStore.displayPageNotification(`Severe error occurred trying to suspend users for comp code ${this.compCode}.`, "red")
                    break;
            }
        });
        this.commonStore.processingApiCall(false);
    }

    public suspendUser = async (user: User) => {
        this.commonStore.processingApiCall(true);
        CommonUtils.logNewRelicPageAction("Dealer Account Management", {userAction: 'Suspend User', compCode: this.compCode, userId:user.userId})

        await suspendUser(this.authStore.token, this.authStore.user?.email!, user.userId).then((resp: any) => {
            switch (resp?.status) {
                case apiStatus.success :
                    this.getUsersByCompCode().then(() => undefined)
                    this.commonStore.displayFeatureNotification("User successfully suspended", "green");
                    break;
                case apiStatus.forbidden :
                    this.commonStore.displayPageNotification(resp.damDefaultErrorMessage, "orange")
                    break;
                default :
                    CommonUtils.displayConsoleLogError(`*** Error calling UAS to suspend user ${user.userId}.  Response: ` + JSON.stringify(resp));
                    this.commonStore.displayPageNotification(`Severe error occurred trying to suspend user ${user.firstName} ${user.lastName}.`, "red")
                    break;
            }
        });
        this.commonStore.processingApiCall(false);
    }

    public unSuspendUser = async (user: User) => {
        this.commonStore.processingApiCall(true);
        CommonUtils.logNewRelicPageAction("Dealer Account Management", {userAction: 'Unsuspend User', compCode: this.compCode, userId:user.userId})

        //this method is a toggle in UAS
        await unsuspendUser(this.authStore.token, this.authStore.user?.email!, user.userId).then((resp: any) => {
            switch (resp?.status) {
                case apiStatus.success :
                    this.getUsersByCompCode().then(() => undefined)
                    this.commonStore.displayFeatureNotification("User successfully Unsuspended", "green");
                    break;
                case apiStatus.forbidden :
                    this.commonStore.displayPageNotification(resp.damDefaultErrorMessage, "orange")
                    break;
                default :
                    CommonUtils.displayConsoleLogError(`*** Error calling UAS to suspend user ${user.userId}.  Response: ` + JSON.stringify(resp));
                    this.commonStore.displayPageNotification(`Severe error occurred trying to Unsuspend user ${user.firstName} ${user.lastName}.`, "red")
                    break;
            }
        });
        this.commonStore.processingApiCall(false);
    }

    public unsuspendAllUsers = async () => {
        this.commonStore.processingApiCall(true);
        CommonUtils.logNewRelicPageAction("Dealer Account Management", {userAction: 'Unsuspend All', compCode: this.compCode})

        await unsuspendAll(this.authStore.token, this.authStore.user?.email!, this.compCode).then((resp: any) => {
            switch (resp?.status) {
                case apiStatus.success :
                    this.getUsersByCompCode().then(undefined)
                    this.commonStore.displayFeatureNotification("All suspended users successfully Unsuspended", "green");
                    break;
                case apiStatus.forbidden :
                    this.commonStore.displayPageNotification(resp.damDefaultErrorMessage, "orange")
                    break;
                default :
                    CommonUtils.displayConsoleLogError(`*** Error calling UAS to Unsuspend all suspended users for comp code ${this.compCode}.  Response: ` + JSON.stringify(resp));
                    this.commonStore.displayPageNotification(`Severe error occurred trying to Unsuspend all users for comp code ${this.compCode}.`, "red")
                    break;
            }
        });
        this.commonStore.processingApiCall(false);
    }

    public handleConfirmSuspendAllModelOpen = () => {
        this.isConfirmSuspendAllModalVisible = true;
    }

    public handleConfirmSuspendAllClose = (confirmed?:boolean) => {
        this.isConfirmSuspendAllModalVisible = false;
        if (confirmed === true) {
            this.suspendAllUsers().then(() => undefined)
        }
    }

    public handleConfirmSuspendUserModelOpen = (user: User) => {
        this.isConfirmSuspendUserModalVisible = true;
        this.editingUser = CommonUtils.cloneObject(user);
    }

    public handleConfirmSuspendUserClose = (confirmed?:boolean) => {
        this.isConfirmSuspendUserModalVisible = false;
        if (confirmed === true && this.editingUser) {
            this.suspendUser(this.editingUser).then(() => undefined)
        }
    }

    public handleDeactivateDeleteUserModalOpen = (user: User) => {
        this.isConfirmDeactivateDeleteModalVisible = true;
        this.editingUser = CommonUtils.cloneObject(user);
    }

    public handleDeactivateDeleteUserModalClose = (confirmed?:boolean) => {
        this.isConfirmDeactivateDeleteModalVisible = false;
        if(confirmed === true) {
            this.deactivateUser(this.editingUser).then(() => undefined)
        }
    }

    public enableSuspendAllButton = () : boolean => {
        return (
            this.userRecords?.length > 0  &&
            this.userRecords?.some(user => {
                return !user.suspended
            })
        )
    }

    public enableUnsuspendAllButton = () : boolean => {
        return (
            this.userRecords?.length > 0  &&
            this.userRecords?.some(user => {
                return user.suspended
            })
        )
    }

    public displayCreateLegacyUserModal = () => {
        this.isCreateLegacyUserModalVisible = true;
    };

    public closeCreateLegacyUserModal = () => {
        this.createLegacyUserName = "";
        this.isCreateLegacyUserModalVisible = false;
    };

    public onCreateLegacyUserNameChange = (nameOnReport: string) => {
      this.createLegacyUserName = nameOnReport;
    };

    public createLegacyUser = async () : Promise<any> => {
        this.commonStore.processingApiCall(true);

        CommonUtils.logNewRelicPageAction("Dealer Account Management", {userAction: 'Create Legacy User', compCode: this.compCode})

        await createLegacyUser(this.authStore.token, this.compCode, this.createLegacyUserName).then((resp: any) => {
            switch (resp?.status) {
                case apiStatus.success :
                    this.getLegacyUsersByCompCode()
                    this.commonStore.displayFeatureNotification(`Successfully created legacy user ${this.createLegacyUserName}`, "green");
                    this.closeCreateLegacyUserModal();
                    break;
                default :
                    CommonUtils.displayConsoleLogError(`*** Error calling UAS to create Legacy user for comp code ${this.compCode}.  Response: ` + JSON.stringify(resp));
                    this.commonStore.displayPageNotification(`Severe error occurred trying to create Legacy user for comp code ${this.compCode}`, "red")
                    break;
            }
        });
        this.commonStore.processingApiCall(false);
        this.closeCreateLegacyUserModal();
    }

    public displayEditLegacyUserModal = (legacyUser: LegacyUser) => {
        this.isEditLegacyUserModalVisible = true
        this.editingLegacyUser = CommonUtils.cloneObject(legacyUser)
        this.originalLegacyUser = legacyUser
        this.commonStore.clearPageMsg()
        this.commonStore.clearFeatureMsg()
    };

    public editLegacyUser = async () : Promise<any> => {
        this.commonStore.processingApiCall(true)

        CommonUtils.logNewRelicPageAction("Dealer Account Management", {userAction: 'Edit Legacy User', compCode: this.compCode})

        await editLegacyUser(this.authStore.token, this.editingLegacyUser!).then((resp: any) => {
            switch (resp?.status) {
                case apiStatus.success :
                    this.getLegacyUsersByCompCode()
                    this.commonStore.displayFeatureNotification(`Successfully edited legacy user ${this.editingLegacyUser?.username}.`, "green");
                    this.closeEditLegacyUserModal();
                    break;
                default :
                    CommonUtils.displayConsoleLogError(`*** Error calling UAS to edit Legacy user ${this.editingLegacyUser?.username} for comp code ${this.compCode}.  Response: ` + JSON.stringify(resp));
                    this.commonStore.displayPageNotification(`Severe error occurred trying to edit Legacy user ${this.editingLegacyUser?.username} for comp code ${this.compCode}.`, "red")
                    break;
            }
        });

        this.commonStore.processingApiCall(false)
        this.closeEditLegacyUserModal()
    };

    public get legacyUserStatus() {
        if (this.editingLegacyUser?.status === "A") {
            return "Active"
        }
        return "Inactive"
    }

    public onLegacyUserNameChange = (value: string) => {
        console.log("onLegacyUserNameChange: " + value)
        this.editingLegacyUser!.name = value
    }

    public onLegacyUserPasswordChange = (value: string) => {
        console.log("onLegacyUserNameChange: " + value)
        this.editingLegacyUser!.password = value
    }

    public onSelectLegacyUserStatus = (selected: string) => {
        let selectedStatus: string;

        if(selected === "Active") {
            selectedStatus = "A"
        } else {
            selectedStatus = "I"
        }

        this.editingLegacyUser!.status = selectedStatus;
    };

    public generatePassword = () : any => {
        this.editingLegacyUser!.password = GeneratePasswordBrowser.generate({
            length: 8,
            numbers: true,
            uppercase: true,
            symbols: false,
            excludeSimilarCharacters: true
        });
    };

    public closeEditLegacyUserModal = () : any => {
        this.isEditLegacyUserModalVisible = false
        this.editingLegacyUser = null
        this.originalLegacyUser = null
    };

    public async onCnameLoginToggle(checked: boolean) {
        if(!checked) {
            this.isCnameAuthStatusModalVisible = true;
        }
        else {
            this.isCnameAuthStatusModalVisible = false;
            await this.disableCnameAuthStatus(false);
        }
    }

    public disableCnameAuthStatus = async (confirmed?: boolean) => {
        let disableCnameAuthStatus: boolean = false;
        if(confirmed) {
            disableCnameAuthStatus = true;
        }
        if((this.cnameAuthStatus.disabled !== undefined && this.cnameAuthStatus.disabled !== disableCnameAuthStatus) ||
            (this.cnameAuthStatus.disabled === undefined && disableCnameAuthStatus)){
            this.cnameAuthStatus.disabled = disableCnameAuthStatus;
            await this.updateLegacyUsersAuthStatus().then(() => {
                this.getLegacyUsersAuthStatus()
            });
        }
        runInAction(() => {
            this.isCnameAuthStatusModalVisible = false;
        });
    }

    public getLegacyUserLastUpdatedAuthStatus = () => {
        let translateLastUpdatedAuthStatusInfo = (updatedBy: string, updatedOn: number) => {
            return `Last Modified by ${updatedBy.replace(/@carfax.com/gi,"")} `
                + `on ${CommonUtils.convertEpochDateToMMDDYYYY(updatedOn)}`
        }
        let cnameLastUpdatedInfo = "";
        if(this.cnameAuthStatus.lastChangedBy && this.cnameAuthStatus.lastChangedBy !== "") {
            cnameLastUpdatedInfo = translateLastUpdatedAuthStatusInfo(this.cnameAuthStatus.lastChangedBy, this.cnameAuthStatus.lastChangedDate)
        }
        else {
            if(this.cnameAuthStatus.createdBy && this.cnameAuthStatus.createdBy !== "") {
                cnameLastUpdatedInfo = translateLastUpdatedAuthStatusInfo(this.cnameAuthStatus.createdBy, this.cnameAuthStatus.createDate)
            }
        }
        return cnameLastUpdatedInfo
    }

    public initializeDevCfoRemoteLoginModal() {
        this.devCfoRemoteLoginEnv = DEFAULT_REMOTE_ENV;
        this.validDevCfoRemoteLoginEnvErrorMessage = "";
        this.devCfoRemoteLoginResolvedUrl = urlBuilder.buildDevRemoteUrl(this.selectedUser, this.devCfoRemoteLoginEnv)
    };

    public displayDevCfoRemoteLoginModal = async (selectedUser: User) => {
        this.isDevCfoRemoteLoginModalVisible = true;
        this.selectedUser = selectedUser;
        this.initializeDevCfoRemoteLoginModal();
    };

    public closeDevCfoRemoteLoginModal = () => {
        this.isDevCfoRemoteLoginModalVisible = false;
    };

    public onDevRemoteCfoLoginEnvChange = (devRemoteCfoLoginUrl: string) => {
        const error_name = "devRemoteCfoLoginEnv"
        if ( devRemoteCfoLoginUrl.indexOf(" ") >= 0) {
            this.validDevCfoRemoteLoginEnvErrorMessage = "Env Name cannot contain spaces.";
            this.commonStore.setFormError(error_name, this.validDevCfoRemoteLoginEnvErrorMessage);
        } else if (devRemoteCfoLoginUrl.length === 0) {
            this.validDevCfoRemoteLoginEnvErrorMessage = "Env Name needed.";
            this.commonStore.setFormError(error_name, this.validDevCfoRemoteLoginEnvErrorMessage);
        } else {
            this.validDevCfoRemoteLoginEnvErrorMessage = "";
            this.commonStore.removeFormError(error_name);
        }
        this.devCfoRemoteLoginEnv = devRemoteCfoLoginUrl;
        this.devCfoRemoteLoginResolvedUrl = urlBuilder.buildDevRemoteUrl(this.selectedUser, this.devCfoRemoteLoginEnv)
    };

    public handleDevCfoRemoteLogin = async () => {
        // console.log("authUrl: " + devRemoteUrl);
        window.open(this.devCfoRemoteLoginResolvedUrl,  "cfo_remote");

        this.closeDevCfoRemoteLoginModal()
    };


    public get validDevCfoRemoteLoginEnv() {
        return (this.validDevCfoRemoteLoginEnvErrorMessage.length === 0);
    }

    public isUserInvitedOrReinvited(user: User) : boolean {
        return (user?.status === "Invited" || user?.status === "Reinvited")
    }

    public handlePaginationChange = (data: any) => {

        this.commonStore.processingApiCall(true)
        this.userRecords = [];
        CommonUtils.logNewRelicPageAction("Dealer Account Management Details", {userAction: 'Paginate Through Dealer Records'})

        this.paginationCurrentPage = data.currentPage;
        this.paginationPerPageValue = data.perPageValue;
        this.getUsersByCompCode(this.paginationCurrentPage).then(() => this.commonStore.processingApiCall(false))
    }

}